import { useAuth } from "~/hooks/use-auth"

type Permissions = {
  canAccessFlexCurricula: boolean
  canDownloadFlexContent: boolean
}

export function usePermissions(): Permissions {
  const { permissions } = useAuth()

  return {
    canAccessFlexCurricula: permissions.has("flex:curricula:access"),
    canDownloadFlexContent: permissions.has("flex:content:download"),
  }
}
